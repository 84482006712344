import React, { useRef, useState, useCallback, useEffect } from 'react';
import classNames from 'classnames/bind';
import styles from './map.module.scss';
import MapNavigation from './components/MapNavigation';
import './geocoderStyle.scss';
import 'mapbox-gl/dist/mapbox-gl.css';
import { FlyToInterpolator } from '@deck.gl/core';
import MapGL, { NavigationControl, ScaleControl, Marker } from 'react-map-gl';

import MAP_STYLE from './components/mapStyle';
import MarkerItem from './components/Marker/MarkerItem';
import { dealerResponse } from 'store/step3PageDataSlice';
import { getWindowWidth } from 'utils/getWindowWidth';

const cx = classNames.bind(styles);

const MapComponent = ({ dealerList, mapBoxKey }) => {
  const width = getWindowWidth();
  const [viewport, setViewport] = useState({
    longitude: 30.702624263115368,
    latitude: 48.88689275970395,
    zoom: 5.5,
  });
  const [activeMarker, setActiveMarker] = useState(null);
  const mapRef = useRef();
  const geocoderRef = useRef();
  const [showModalMarker, setShowModalMarker] = useState(false);
  const [modalMarkerContent, setModalMarkerContent] = useState(null);

  const handleViewportChange = useCallback(
    (newViewport) => setViewport(newViewport),
    [],
  );

  const handleGeocoderViewportChange = useCallback(
    (newViewport) => {
      const geocoderDefaultOverrides = { transitionDuration: 1000 };

      return handleViewportChange({
        ...newViewport,
        ...geocoderDefaultOverrides,
      });
    },
    [handleViewportChange],
  );

  useEffect(() => {
    if (width > 1200) {
      setViewport({
        ...viewport,
        zoom: 5.5,
      });
    } else if (width < 1200 && width > 767) {
      setViewport({
        ...viewport,
        zoom: 4.5,
      });
    } else if (width < 767) {
      setViewport({
        ...viewport,
        zoom: 4,
      });
    }
  }, [width]);

  return (
    <div className={cx('mapWrap')}>
      <MapGL
        ref={mapRef}
        {...viewport}
        className={cx('map-container')}
        mapboxApiAccessToken={mapBoxKey}
        mapStyle={MAP_STYLE}
        onViewportChange={handleViewportChange}
        interactiveLayerIds={['sf-neighborhoods-fill']}
        width="100%"
        // height={width > 767 ? 'calc(100vh - 192px)' : 'calc(100vh - 167px)'}
      >
        {dealerList &&
          dealerList.map((el, index) => (
            <MarkerItem
              key={index}
              item={el}
              number={index + 1}
              onClickFc={(position) => {
                handleViewportChange(position);
              }}
              fetchMarkerContent={(content) => setModalMarkerContent(content)}
              showModal={(show) => setShowModalMarker(show)}
              activeMarker={activeMarker}
              activeMarkerHandler={(number) => setActiveMarker(number)}
            />
          ))}
      </MapGL>
      <MapNavigation
        onViewportChange={(position) => {
          handleViewportChange(position);
          setActiveMarker(null);
        }}
        // fetchLocation={getCurrentLocation}
        mapRef={mapRef}
        geocoderRef={geocoderRef}
        handleGeocoderViewportChange={handleGeocoderViewportChange}
        mapBoxToken={mapBoxKey}
      />
      {/* <div className={cx('modalMarker')}></div> */}
    </div>
  );
};

export default MapComponent;
