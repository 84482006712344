import React from 'react';
import { createRoot } from 'react-dom/client';
// import "mapbox-gl/dist/mapbox-gl.css";
import './index.css';
import './variables.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);
root.render(<App />);

reportWebVitals();
