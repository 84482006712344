import React, { useMemo, useState } from 'react';
import classNames from 'classnames/bind';
import styles from './header.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
  displayedStep,
  setActualStep,
  successfulStep_1,
  successfulStep_2,
  successfulStep_3,
  successfulStep_4,
} from 'store/formStepSlice';
import CheckWhiteIcon from 'assets/svg/CheckWhiteIcon';
import { getHeaderData } from 'api/common';
import { urlParams } from 'store/urlParamsSlice';
import { setErrorData } from 'store/errorsSlice';
import { useEffect } from 'react';
import { headerResponce, setHeaderResponce } from 'store/headerSlice';

const cx = classNames.bind(styles);

const Header = ({ title }) => {
  const dispatch = useDispatch();
  const actualStep = useSelector(displayedStep);
  const successStep_1 = useSelector(successfulStep_1);
  const successStep_2 = useSelector(successfulStep_2);
  const successStep_3 = useSelector(successfulStep_3);
  const successStep_4 = useSelector(successfulStep_4);
  const urlParamsData = useSelector(urlParams);
  const headerData = useSelector(headerResponce);

  const { dealer, source } = urlParamsData;

  const fetchHeaderData = () => {
    if (!headerData) {
      getHeaderData(urlParamsData)
        .then((res) => {
          dispatch(setHeaderResponce(res.data.data));
        })
        .catch((error) => {
          dispatch(setErrorData(error.response));
        });
    }
  };

  const isActiveStep = (step) => {
    if (actualStep === step) {
      return 'active';
    } else {
      return '';
    }
  };

  const choseStep = (step) => {
    dispatch(setActualStep(step));
  };

  useEffect(() => {
    fetchHeaderData();
  }, []);

  return (
    <div className={cx('header')}>
      <div className="container">
        <h1 className={cx('title')}>
          {(headerData && headerData.titles['step_' + actualStep]) || ''}
        </h1>
        <div className={cx('steps')}>
          <ul className={cx('steps__list')}>
            <li
              className={cx('steps__list-item', isActiveStep(1))}
              onClick={() => choseStep(1)}
            >
              <div
                className={
                  successStep_1 ? cx('number', 'successStep') : cx('number')
                }
              >
                {successStep_1 ? <CheckWhiteIcon /> : 1}
              </div>
              <div className={cx('label')}>{headerData?.tabs.tab_1}</div>
            </li>

            <li
              className={cx('steps__list-item', isActiveStep(2))}
              onClick={() => (successStep_1 ? choseStep(2) : null)}
            >
              <div
                className={
                  successStep_2 ? cx('number', 'successStep') : cx('number')
                }
              >
                {successStep_2 ? <CheckWhiteIcon /> : 2}
              </div>
              <div className={cx('label')}>{headerData?.tabs.tab_2}</div>
            </li>

            {source === 'frame' ||
              (source === 'fr' ? (
                ''
              ) : (
                <li
                  className={cx('steps__list-item', isActiveStep(3))}
                  onClick={() => (successStep_2 ? choseStep(3) : null)}
                >
                  <div
                    className={
                      successStep_3 ? cx('number', 'successStep') : cx('number')
                    }
                  >
                    {successStep_3 ? <CheckWhiteIcon /> : 3}
                  </div>
                  <div className={cx('label')}>{headerData?.tabs.tab_3}</div>
                </li>
              ))}

            <li
              className={cx('steps__list-item', isActiveStep(4))}
              onClick={() => (successStep_3 ? choseStep(4) : null)}
            >
              <div
                className={
                  successStep_4 ? cx('number', 'successStep') : cx('number')
                }
              >
                {source === 'frame' || source === 'fr' ? (
                  <>{successStep_4 ? <CheckWhiteIcon /> : 3}</>
                ) : (
                  <>{successStep_4 ? <CheckWhiteIcon /> : 4}</>
                )}
              </div>
              <div className={cx('label')}>{headerData?.tabs.tab_4}</div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Header;
