import React from 'react';

import classNames from 'classnames/bind';
import styles from './ModalComponent.module.scss';
import { Box, Modal } from '@mui/material';
import CloseIcon from 'assets/svg/CloseIcon';

const cx = classNames.bind(styles);

const ModalComponent = ({
  children,
  handleOpen,
  handleClose,
  maxWidth = '536',
  minWidth = null,
}) => {
  let style = {};
  if (minWidth) {
    style = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      bgcolor: 'background.paper',
      boxShadow: 24,
      width: '100%',
      maxWidth: `${maxWidth}px!important`,
      minWidth: `${minWidth}!important`,
    };
  } else {
    style = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      width: '100%',
      transform: 'translate(-50%, -50%)',
      bgcolor: 'background.paper',
      boxShadow: 24,
      maxWidth: `${maxWidth}px!important`,
    };
  }
  return (
    <Modal open={handleOpen} onClose={handleClose}>
      <Box sx={style}>
        <div className={cx('modal')}>
          <div className={cx('modal__close-btn')} onClick={() => handleClose()}>
            <CloseIcon />
          </div>
          <div className={cx('modal__content')}>{children}</div>
        </div>
      </Box>
    </Modal>
  );
};

export default ModalComponent;
