import React, { useState, useEffect } from 'react';
import classNames from 'classnames/bind';
import styles from './Step4.module.scss';
import Header from 'components/Header';
import { useController, useForm } from 'react-hook-form';
import FormInput from 'components/FormInput';
import Button from 'components/Button';
import format from 'date-fns/format';
import { useDispatch, useSelector } from 'react-redux';
import {
  setActualStep,
  setSuccessfulStep_1,
  setSuccessfulStep_2,
  setSuccessfulStep_3,
  setSuccessfulStep_4,
} from 'store/formStepSlice';
import ArrowCheck from 'assets/svg/arrowCheck';
import CheckBoxInput from 'components/CheckBoxInput';
import PhoneInput from 'components/PhoneInput';
import Select from 'components/Select';
import DatePicker from 'components/DatePicker';
import TabsComponent from 'components/TabsComponent';
import ShowMoreContainer from 'components/ShowMoreContainer';
import ModalComponent from '../../components/Modal/ModalComponent';
import ChatBotTabs from '../../components/ChatBotTabs/ChatBotTabs';
import { getStepPage_4, sendServiceRequest } from 'api/step_4';
import { setErrorData } from 'store/errorsSlice';
import { setStep4Response, step4PageResponse } from 'store/step4PageDataSlice';
import { urlParams } from 'store/urlParamsSlice';
import {
  resetStep3,
  selectedDealer,
  setStaticDealer,
  setStep3Response,
} from 'store/step3PageDataSlice';
import { firstStepData, setFirstStepData } from 'store/formDataSlice';
import Spinner from 'components/Spinner/Spinner';
import { setStep2Response } from 'store/step2PageDataSlice';
import { getErrorText } from 'utils/getErrorText';
import CheckBoxInputGroup from 'components/CheckBoxInputGroup';
import { getWindowWidth } from 'utils/getWindowWidth';

const cx = classNames.bind(styles);

const Step4 = () => {
  const {
    handleSubmit,
    control,
    getValues,
    setFocus,
    formState: { errors },
    register,
    watch,
  } = useForm({
    reValidateMode: 'onChange',
    defaultValues: {
      service: true,
    },
  });

  const [showAdditionalServices, setShowAdditionalServices] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showDynamicPopup, setShowDynamicPopup] = useState(false);
  const [dynamicPopupContent, setDynamicPopupContent] = useState(null);
  const [successfulPopupContent, setSuccessfulPopupContent] = useState(null);
  const [additionServicesIds, setAdditionServicesIds] = useState([]);
  const dispatch = useDispatch();
  const pageData = useSelector(step4PageResponse);
  const [isLoading, setIsLoading] = useState(true);

  const urlParamsData = useSelector(urlParams);
  const dealer = useSelector(selectedDealer);
  const { vin, mileage } = useSelector(firstStepData);

  const mainService = watch('service');
  const mobile = getWindowWidth() < 567;

  const getStepPageData = () => {
    // setIsLoading(true);

    let data = {
      source: urlParamsData.source,
      mark: urlParamsData.mark,
      lang: urlParamsData.lang,
      dealer: dealer,
      vin: vin,
      mileage: mileage,
    };
    // let data = {
    //   source: urlParamsData.source,
    //   mark: urlParamsData.mark,
    //   lang: urlParamsData.lang,
    //   dealer: 'AZ',
    //   vin: 'JTMW43FV80D055467',
    //   mileage: '130000',
    // };
    getStepPage_4(data)
      .then((res) => {
        dispatch(setStep4Response(res.data.data));
        setIsLoading(false);
      })
      .catch((error) => {
        dispatch(setErrorData(error.response));
        dispatch(setActualStep('errorPage'));
        setIsLoading(false);
      });
  };

  const checkValidTime = (value) => {
    const time = new Date(value).toLocaleTimeString();

    if (time === '00:00:00') {
      return false;
    } else {
      return true;
    }
  };

  const renderFormFields = (field) => {
    switch (field.type) {
      case 'text':
        return (
          <FormInput
            name={field.name}
            type="text"
            label={field.placeholder}
            control={control}
            rules={{
              required: getErrorText(field.validateTextError, 'required'),
              validate: {
                space: (value) =>
                  !/^\s*$/.test(value) ||
                  getErrorText(field.validateTextError, 'required'),
              },
            }}
            errors={errors[field.name]}
          />
        );
      case 'phone':
        return (
          <PhoneInput
            control={control}
            label={field.placeholder}
            name={field.name}
            setFocus={setFocus}
            rules={{
              required: getErrorText(field.validateTextError, 'required'),
            }}
            errors={errors[field.name]}
          />
        );
      case 'email':
        return (
          <FormInput
            name={field.name}
            type="text"
            label={field.placeholder}
            control={control}
            rules={{
              required: getErrorText(field.validateTextError, 'required'),
              validate: {
                space: (value) =>
                  /^([a-zA-Z0-9._-]+)@([a-zA-Z0-9_-]+)\.([a-zA-Z]{2,5})$/.test(
                    value,
                  ) || getErrorText(field.validateTextError, 'email'),
              },
            }}
            errors={errors[field.name]}
          />
        );
      case 'select':
        return (
          <Select
            control={control}
            options={field.items}
            label={field.placeholder}
            name={field.name}
            isSearchable={false}
            rules={{
              required: getErrorText(field.validateTextError, 'required'),
            }}
            errors={errors[field.name]}
          />
        );
      case 'calendar':
        return (
          <DatePicker
            label={field.placeholder}
            name={field.name}
            control={control}
            dateFormat="HH:mm, d MMMM yyyy"
            autoComplete="off"
            rules={{
              required: getErrorText(field.validateTextError, 'required'),
              validate: {
                validateTime: (value) =>
                  checkValidTime(value) ||
                  getErrorText(field.validateTextError, 'validTime'),
              },
            }}
            errors={errors[field.name]}
            requestServiceIn={pageData?.dealerCenter?.requestServiceIn}
          />
        );

      default:
        return '';
    }
  };

  const changeFirstStepData = () => {
    dispatch(setActualStep(1));
    dispatch(setSuccessfulStep_1(false));
    dispatch(setSuccessfulStep_2(false));
    dispatch(setSuccessfulStep_3(false));
    dispatch(setSuccessfulStep_4(false));

    dispatch(setStep2Response({}));
    dispatch(resetStep3());
    dispatch(setFirstStepData({}));
    dispatch(setStep4Response({}));
  };

  const changeDealer = () => {
    dispatch(setActualStep(3));
    dispatch(setSuccessfulStep_3(false));
    dispatch(setSuccessfulStep_4(false));

    dispatch(setStep3Response({}));
    dispatch(setStaticDealer(null));
    dispatch(setStep4Response({}));
  };
  const onSubmit = (data) => {
    setIsLoading(true);
    let sendData = {
      ...data,
      connectionType: data.connectionType.key,
      dealerServices: additionServicesIds,
      dealer: dealer,
      vin: vin,
      visitDate: format(new Date(data.visitDate), 'yyyy-MM-dd HH:mm'),
      service: data.service ? '1' : '0',
      carModel: pageData?.infoCar?.carModel,
      lastTOMileage: pageData?.infoCar?.lastToMileage,
      mileage: mileage,
    };

    sendServiceRequest(urlParamsData, sendData)
      .then((res) => {
        if (res.status === 200) {
          setIsLoading(false);
          setSuccessfulPopupContent(res.data.data);
          setShowModal(true);
        }
      })
      .catch((error) => {
        dispatch(setErrorData(error.response));
        dispatch(setActualStep('errorPage'));
        setIsLoading(false);
      });
  };
  useEffect(() => {
    !Object.keys(pageData).length && getStepPageData();
    Object.keys(pageData).length && setIsLoading(false);
  }, []);

  const renderDynamicPopup = (content) => {
    return (
      <ModalComponent
        handleOpen={showDynamicPopup}
        handleClose={() => setShowDynamicPopup(false)}
        maxWidth={800}
      >
        <div className={cx('modal', 'privacy')}>
          <div className={cx('privacy')}>{content}</div>
        </div>
      </ModalComponent>
    );
  };

  return (
    <>
      <div className={cx('Step4')}>
        <Header title="Запис на сервіс" stepNumber="4" />
        {!isLoading ? (
          <div className="container">
            <form
              className={cx('serviceInfo', 'form')}
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className={cx('serviceInfo__block', 'carInfo')}>
                <div className={cx('serviceInfo__block-title')}>
                  <div className={cx('text')}>{pageData?.infoCar?.title}</div>
                  <div
                    className={cx('editBtn')}
                    onClick={() => changeFirstStepData()}
                  >
                    {pageData?.infoCar?.buttonChange}
                  </div>
                </div>
                <ul className={cx('serviceInfo__list')}>
                  {pageData?.infoCar?.fields?.map((el, index) => (
                    <li className={cx('serviceInfo__list-item')} key={index}>
                      <div className={cx('title')}>{el.title}</div>
                      <div className={cx('value')}>{el.value}</div>
                    </li>
                  ))}
                </ul>
              </div>
              <div className={cx('serviceInfo__block', 'dealerInfo')}>
                <div className={cx('serviceInfo__block-title')}>
                  <div className={cx('text')}>
                    {pageData?.dealerCenter?.title}
                  </div>
                  {urlParamsData.dealer === undefined && (
                    <div
                      className={cx('editBtn')}
                      onClick={() => changeDealer()}
                    >
                      {pageData?.dealerCenter?.buttonChange}
                    </div>
                  )}
                </div>
                <ul className={cx('serviceInfo__list')}>
                  {pageData?.dealerCenter?.fields?.map((el, index) => (
                    <li className={cx('serviceInfo__list-item')} key={index}>
                      <div className={cx('title')}>{el.title}</div>
                      <div className={cx('value')}>{el.value}</div>
                    </li>
                  ))}
                </ul>
              </div>
              <div className={cx('serviceInfo__block', 'serviceList')}>
                <div className={cx('serviceInfo__block-title')}>
                  <div className={cx('text')}>{pageData?.service?.title}</div>
                </div>
                <ul className={cx('serviceInfo__list')}>
                  {pageData?.service?.fields?.map((el, index) => (
                    <li className={cx('serviceInfo__list-item')} key={index}>
                      <CheckBoxInput
                        control={control}
                        label={el.label}
                        name="service"
                        value="service"
                        disabled={el.hidden}
                      />
                    </li>
                  ))}
                </ul>
                {!pageData?.service?.fields[0]?.hidden ? (
                  <div
                    className={cx('serviceInfo__disclamer')}
                    dangerouslySetInnerHTML={{
                      __html: pageData.service.disclaimer || '',
                    }}
                  />
                ) : null}
              </div>
              <div className={cx('serviceInfo__block', 'additionalServices')}>
                <div
                  className={
                    !showAdditionalServices
                      ? cx('serviceInfo__block-title', 'active')
                      : cx('serviceInfo__block-title')
                  }
                  onClick={() =>
                    setShowAdditionalServices(!showAdditionalServices)
                  }
                >
                  <div className={cx('text')}>
                    {pageData?.additionServices?.title}
                  </div>
                  <div
                    className={
                      showAdditionalServices
                        ? cx('showMore', 'active')
                        : cx('showMore')
                    }
                  >
                    <ArrowCheck />
                  </div>
                </div>
                <div
                  className={
                    showAdditionalServices
                      ? cx('additionalServices__list', 'active')
                      : cx('additionalServices__list')
                  }
                >
                  <CheckBoxInputGroup
                    items={pageData.additionServices.items}
                    name={pageData.additionServices.name}
                    getAdditionalArr={(arr) => setAdditionServicesIds(arr)}
                  />
                </div>
              </div>
              <div className={cx('serviceInfo__block', 'clienInfo')}>
                <div className={cx('serviceInfo__block-title')}>
                  <div className={cx('text')}>
                    {pageData?.formClientInfo?.title}
                  </div>
                </div>
                <div className={cx('clienInfo__form')}>
                  {pageData?.formClientInfo?.fields?.map((el, index) => (
                    <div
                      className={cx('clienInfo__form-line')}
                      key={el.name + '-' + index}
                    >
                      {renderFormFields(el)}
                    </div>
                  ))}
                </div>
              </div>
              {mainService && pageData.estimatedCostMaintenance !== null ? (
                <div className={cx('serviceInfo__block', 'priceInfo')}>
                  <div className={cx('serviceInfo__block-title')}>
                    <div className={cx('text')}>
                      {pageData?.estimatedCostMaintenance?.title}
                    </div>
                    <div className={cx('price')}>
                      {pageData?.estimatedCostMaintenance?.price}
                    </div>
                  </div>
                  <div
                    className={cx('serviceInfo__block-sub-title')}
                    dangerouslySetInnerHTML={{
                      __html: pageData?.estimatedCostMaintenance?.disclaimer,
                    }}
                  />
                  <div className={cx('tabsWrap')}>
                    <ShowMoreContainer
                      type="allHide"
                      btnTextShow={pageData?.estimatedCostMaintenance?.showLess}
                      btnTextHide={pageData?.estimatedCostMaintenance?.showMore}
                      forceShow={true}
                    >
                      <TabsComponent
                        key="tabsComponent"
                        tabsList={
                          pageData?.estimatedCostMaintenance?.data?.tabs || []
                        }
                        tablesList={
                          pageData?.estimatedCostMaintenance?.data?.tables || {}
                        }
                      />
                    </ShowMoreContainer>
                  </div>
                </div>
              ) : null}

              <div className={cx('form__footer')}>
                {pageData?.agree?.fields?.map((el, index) => (
                  <div className={cx('form__footer-line')} key={index}>
                    <CheckBoxInput
                      control={control}
                      label={`${el.label}`}
                      labellinktext={`${el.linkText}`}
                      labellinkaction={() => {
                        setDynamicPopupContent(el.popup);
                        setShowDynamicPopup(true);
                      }}
                      name={el.name}
                      rules={
                        el.required
                          ? {
                              required: getErrorText(
                                el.validateTextError,
                                'required',
                              ),
                            }
                          : null
                      }
                      errors={errors[el.name]}
                      disabled={el.hidden}
                    />
                  </div>
                ))}
                <div className={cx('form__footer-line', 'buttonWrap')}>
                  <Button btnText={pageData?.buttonSubmit} />
                </div>
              </div>
            </form>
          </div>
        ) : (
          <Spinner />
        )}
      </div>
      <ModalComponent
        handleOpen={showModal}
        handleClose={() => {
          setShowModal(false);
          changeFirstStepData();
        }}
        maxWidth={mobile ? '329' : '536'}
      >
        <div className={cx('modal', 'successful')}>
          <div className={cx('modal__title')}>
            {successfulPopupContent?.title}
          </div>
          <div className={cx('modal__sub-title')}>
            {successfulPopupContent?.subTitle}
          </div>
          {successfulPopupContent?.bots && (
            <ChatBotTabs
              qrCodeData={successfulPopupContent?.bots || []}
              title={successfulPopupContent?.text}
              signupText={successfulPopupContent?.signupText}
            />
          )}
        </div>
      </ModalComponent>
      <ModalComponent
        handleOpen={showDynamicPopup}
        handleClose={() => setShowDynamicPopup(false)}
        maxWidth={mobile ? '329' : '536'}
      >
        <div className={cx('modal', 'privacy')}>
          <div dangerouslySetInnerHTML={{ __html: dynamicPopupContent }}></div>
        </div>
      </ModalComponent>
    </>
  );
};

export default Step4;
