import React, { useState, useRef, useEffect } from 'react';
import classNames from 'classnames/bind';
import styles from './Marker.module.scss';
import MarkerIcon from 'assets/svg/MarkerIcon';
import ActiveMarkerIcon from 'assets/svg/ActiveMarkerIcon';
import { FlyToInterpolator } from '@deck.gl/core';
import MapGL, { Marker } from 'react-map-gl';
import ClickOutside from 'react-click-outside';
import { useOnClickOutside } from 'utils/useClickOutside';
import { useDispatch } from 'react-redux';
import { setSelectedDealer, setStaticDealer } from 'store/step3PageDataSlice';
import {
  setActualStep,
  setSuccessfulStep_3,
  setSuccessfulStep_4,
} from 'store/formStepSlice';
import { setStep4Response } from 'store/step4PageDataSlice';
import Button from 'components/Button';
import { getWindowWidth } from 'utils/getWindowWidth';
import ModalComponent from 'components/Modal/ModalComponent';
import CloseIcon from 'assets/svg/CloseIcon';
const cx = classNames.bind(styles);

const MarkerItem = ({
  onClick,
  item,
  clickedMarker,
  number,
  onClickFc,
  activeMarker,
  activeMarkerHandler,
  fetchMarkerContent,
  // showModal = false,
}) => {
  const [showModal, setShowModal] = useState(false);
  const modalRef = useRef(null);
  const dispatch = useDispatch();
  const width = getWindowWidth() > 991;

  const activeClass = activeMarker === number ? true : false;

  useOnClickOutside(modalRef, () => {
    setShowModal(false);
  });

  useEffect(() => {
    !showModal && activeMarkerHandler(null);
  }, [showModal]);

  const onSelectDealer = (dealerCode) => {
    dispatch(setSelectedDealer(dealerCode));
    dispatch(setSuccessfulStep_3(true));
    dispatch(setSuccessfulStep_4(false));
    dispatch(setStaticDealer(null));
    dispatch(setStep4Response({}));
    dispatch(setActualStep(4));
  };

  return (
    <Marker
      key={number}
      latitude={Number(item?.geoLocation?.lat)}
      longitude={Number(item?.geoLocation?.lon)}
      anchor="center"
      className={cx('markerWrap', activeClass ? cx('active') : '')}
    >
      <button
        ref={modalRef}
        className={cx('marker', activeClass ? cx('active') : '')}
        onClick={() => {
          activeMarkerHandler(number);
          onClickFc({
            latitude: Number(item?.geoLocation?.lat),
            longitude: Number(item?.geoLocation?.lon),
            transitionDuration: 1000,
            zoom: 17,
            transitionInterpolator: new FlyToInterpolator({ speed: 2 }),
          });
          setShowModal(true);
          fetchMarkerContent(item);
        }}
      >
        <span className={cx('marker__icon')}>
          {activeClass ? <ActiveMarkerIcon /> : <MarkerIcon />}
        </span>
        <span className={cx('marker__text')}>{number}</span>
      </button>
      {width ? (
        <div
          className={cx('modalMarker', showModal ? 'show' : '')}
          ref={modalRef}
        >
          <div
            className={cx('modalMarker__close-btn')}
            onClick={() => setShowModal(false)}
          >
            <CloseIcon />
          </div>
          <div className={cx('modalMarker__content')}>
            <div className={cx('modalMarker__item')}>
              <div className={cx('modalMarker__item-row', 'dealer')}>
                <span className={cx('dealer__number', 'active')}>{number}</span>
                <span className={cx('dealer__distance')}>{item.distance}</span>
              </div>
              <div className={cx('modalMarker__item-row')}>
                <span className={cx('dealer__name')}>{item.name}</span>
              </div>
              <div className={cx('modalMarker__item-row', 'btnContainer')}>
                <Button
                  btnText={item.btnChooseDealerText}
                  onClick={() => onSelectDealer(item?.dealerCode)}
                />
              </div>
              <div className={cx('modalMarker__item-row')}>
                <div className={cx('modalMarker__contacts')}>
                  {item?.fields.map((el, index) => (
                    <div
                      className={cx('modalMarker__contacts-line')}
                      key={`index-${index}`}
                    >
                      <span>{el.label}</span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: el.value || '',
                        }}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <ModalComponent
          handleOpen={showModal}
          handleClose={() => setShowModal(false)}
          maxWidth={800}
          minWidth={'360px'}
          ref={modalRef}
        >
          <div className={cx('modal')} ref={modalRef}>
            <div className={cx('modalMarker__item')}>
              <div className={cx('modalMarker__item-row', 'dealer')}>
                <span className={cx('dealer__number', 'active')}>{number}</span>
                <span className={cx('dealer__distance')}>{item.distance}</span>
              </div>
              <div className={cx('modalMarker__item-row')}>
                <span className={cx('dealer__name')}>{item.name}</span>
              </div>
              <div className={cx('modalMarker__item-row', 'btnContainer')}>
                <Button
                  btnText={item.btnChooseDealerText}
                  onClick={() => onSelectDealer(item?.dealerCode)}
                />
              </div>
              <div className={cx('modalMarker__item-row')}>
                <div className={cx('modalMarker__contacts')}>
                  {item?.fields.map((el, index) => (
                    <div
                      className={cx('modalMarker__contacts-line')}
                      key={`index-${index}`}
                    >
                      <span>{el.label}</span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: el.value || '',
                        }}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </ModalComponent>
      )}
    </Marker>
  );
};

export default MarkerItem;
