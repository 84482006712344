import * as React from "react";

const CurrentLocationIcon = (props) => (
  <svg
    width={16}
    height={16}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        clipRule="evenodd"
        d="M8 .8C5.76.8 4 2.64 4 4.96c0 .56.16 1.2.32 1.68L8 13.6l3.68-6.96c.16-.48.32-1.12.32-1.68C12 2.64 10.24.8 8 .8Z"
        stroke="#6C6F76"
        strokeWidth={1.5}
      />
      <path d="M8 6a1.2 1.2 0 1 0 0-2.4A1.2 1.2 0 0 0 8 6Z" fill="#6C6F76" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default CurrentLocationIcon;
