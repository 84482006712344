import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  pageResponse: {},
  dealerResponse: null,
  selectedDealer: null,
  showDealerList: false,
  showError: false,
  staticDealer: null,
};

export const step3PageDataSlice = createSlice({
  name: 'step3PageResponse',
  initialState,
  reducers: {
    setStep3Response: (state, action) => {
      state.pageResponse = action.payload;
    },
    setDealersResponse: (state, action) => {
      state.dealerResponse = action.payload;
    },
    setSelectedDealer: (state, action) => {
      state.selectedDealer = action.payload;
    },
    setShowDealerList: (state, action) => {
      state.showDealerList = action.payload;
    },
    setShowError: (state, action) => {
      state.showError = action.payload;
    },
    setStaticDealer: (state, action) => {
      state.staticDealer = action.payload;
    },
    resetStep3: (state) => {
      state.pageResponse = initialState.pageResponse;
      state.dealerResponse = initialState.dealerResponse;
      state.selectedDealer = initialState.selectedDealer;
      state.showDealerList = initialState.showDealerList;
      state.showError = initialState.showError;
      state.staticDealer = initialState.staticDealer;
    },
  },
});

export const step3PageResponse = (state) => state.step3PageData.pageResponse;
export const dealerResponse = (state) => state.step3PageData.dealerResponse;
export const selectedDealer = (state) => state.step3PageData.selectedDealer;
export const showError = (state) => state.step3PageData.showError;
export const showDealerListStatus = (state) =>
  state.step3PageData.showDealerList;
export const staticDealer = (state) => state.step3PageData.staticDealer;

export const {
  setStep3Response,
  setDealersResponse,
  setSelectedDealer,
  setShowDealerList,
  setShowError,
  setStaticDealer,
  resetStep3,
} = step3PageDataSlice.actions;

export default step3PageDataSlice.reducer;
