import { ru, enUS, uk, ro } from 'date-fns/locale';

export const getLang = (lang) => {
  let labels = {
    timeCaption: '',
    lang: enUS,
  };
  switch (lang) {
    case 'uk':
      return (labels = {
        timeCaption: 'Час',
        lang: uk,
      });
    case 'ro':
      return (labels = {
        timeCaption: 'Timp',
        lang: ro,
      });
    case 'en':
      return (labels = {
        timeCaption: 'Time',
        lang: enUS,
      });
    case 'ru':
      return (labels = {
        timeCaption: 'Время',
        lang: ru,
      });
    default:
      return (labels = {
        timeCaption: 'Time',
        lang: enUS,
      });
  }
};
