import axios from 'axios';

const api = axios.create({
  // baseURL: 'https://back.sawa.dev13.vintagedev.com.ua/',
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  timeout: 30000,
});

api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    config.headers['Authorization'] = 'Bearer ' + token;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

api.interceptors.response.use(
  (response) => {
    const { status } = response;
    if (status === 200 || status === 201) {
      return response;
    }
  },
  (error) => {
    // const { status, statusText } = error.response;

    return Promise.reject(error);
  },
);

export default api;
