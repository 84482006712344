import React, { useEffect } from 'react';
import classNames from 'classnames/bind';
import styles from './ErrorPage.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { setActualStep } from 'store/formStepSlice';
import { errorData } from 'store/errorsSlice';

const cx = classNames.bind(styles);

const ErrorPage = () => {
  const dispatch = useDispatch();

  const error = useSelector(errorData);

  useEffect(() => {}, []);
  return (
    <div className={cx('errorPage')}>
      <div className={cx('errorPage__content')}>
        <div className={cx('errorPage__title')}>{error?.status || '404'}</div>
        <div className={cx('errorPage__sub-title')}>
          {error?.data?.message || 'Ця сторінка не існує або була видалена.'}
        </div>
        <div
          className={cx('errorPage__btn')}
          onClick={() => dispatch(setActualStep(1))}
        >
          На головну
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
