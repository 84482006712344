import API from '../api';

export const getStepPage_3 = (params) => {
  let paramsString = new URLSearchParams(params);

  return API.get(`/api/v1/step/three?${paramsString}`);
};

export const searchCityRequest = (params) => {
  return API.post(`/api/v1/step/three/search-city`, params);
};
export const searchDealerRequest = (params) => {
  return API.post(`/api/v1/step/three/search`, params);
};

export const sendCarParams = (params, data) => {
  let paramsString = new URLSearchParams(params);

  return API.post(`/api/v1/step/one?${paramsString}`, data);
};

export const getCityMapBox = (query, mapboxToken, lang) => {
  return API.get(
    `https://api.mapbox.com/geocoding/v5/mapbox.places/${query}.json?access_token=${mapboxToken}&country=ua&language=${lang.code}&types=place,locality,neighborhood,region,postcode`,
  );
};
