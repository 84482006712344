import React from 'react';
import classNames from 'classnames/bind';
import styles from './Step2.module.scss';
import Header from 'components/Header';
import Button from 'components/Button';
import ShowMoreContainer from 'components/ShowMoreContainer';

import Tooltip from 'components/Tooltip/Tooltip';
import { useDispatch, useSelector } from 'react-redux';
import { urlParams } from 'store/urlParamsSlice';
import {
  setActualStep,
  setSuccessfulStep_1,
  setSuccessfulStep_2,
  setSuccessfulStep_3,
} from 'store/formStepSlice';
import { setStep2Response, step2PageResponse } from 'store/step2PageDataSlice';
import { setFirstStepData } from 'store/formDataSlice';
import { setSelectedDealer } from 'store/step3PageDataSlice';
import {
  resetStep3,
  setDealersResponse,
  setStep3Response,
} from 'store/step3PageDataSlice';
const cx = classNames.bind(styles);

const Step2 = () => {
  const dispatch = useDispatch();
  const urlParamsData = useSelector(urlParams);

  const carInfo = useSelector(step2PageResponse);

  const setSuccessfullStep = () => {
    if (urlParamsData.source === 'frame' || urlParamsData.source === 'fr') {
      dispatch(setSelectedDealer(urlParamsData.dealer));
      dispatch(setSuccessfulStep_3(true));
      dispatch(setActualStep(4));
      dispatch(setSuccessfulStep_2(true));
    } else {
      dispatch(setActualStep(3));
      dispatch(setSuccessfulStep_2(true));
    }
  };

  return (
    <div className={cx('Step2')}>
      <Header title={carInfo?.title} stepNumber="2" />
      <div className={cx('container')}>
        <div className={cx('vinInfo')}>
          <div className={cx('vinInfo__header', 'vin')}>
            <div className={cx('vin__info')}>
              <p>{carInfo?.vinBlock?.youNumberLabel}</p>
              <p>{carInfo?.vinBlock?.yourNumber}</p>
            </div>
            <div
              className={cx('vin__btn')}
              onClick={() => {
                dispatch(setActualStep(1));
                dispatch(setSuccessfulStep_1(false));
                dispatch(setSuccessfulStep_2(false));
                dispatch(setStep2Response({}));
                dispatch(setFirstStepData({}));
                dispatch(resetStep3());
              }}
            >
              {carInfo?.vinBlock?.checkOther}
            </div>
          </div>
          <div className={cx('vinInfo__content')}>
            <ul className={cx('info-list')}>
              {carInfo?.fields?.map((item, index) => (
                <li className={cx('info-list__row')} key={index}>
                  <div className={cx('info-list__row-td')}>
                    <div className={cx('td-fildName')}> {item.label}</div>
                    {item.disclaimer && (
                      <Tooltip
                        tooltipText={item.disclaimer}
                        dataEvent="click"
                        dataFor={'tooltip-' + (index + 1)}
                      />
                    )}
                  </div>
                  <div className={cx('info-list__row-td')}>
                    <div className={cx('td-value')}>{item.value}</div>
                  </div>
                </li>
              ))}
            </ul>
            <div className={cx('warrantyResult')}>
              <div
                className={cx(
                  'warrantyResult__icon',
                  carInfo?.sawaResult?.status ? cx('valid') : cx('invalid'),
                )}
              ></div>
              <div className={cx('warrantyResult__text')}>
                <p
                  dangerouslySetInnerHTML={{
                    __html: carInfo?.sawaResult?.title || '',
                  }}
                />
                <ShowMoreContainer
                  btnTextShow={carInfo?.moreOption}
                  btnTextHide={carInfo?.moreOptionHide}
                  type="allHide"
                >
                  <p
                    className={cx('warrantyResult__text-descr')}
                    dangerouslySetInnerHTML={{
                      __html:
                        carInfo?.sawaResult?.message.replace(
                          /\n\r?/g,
                          '<br />',
                        ) || '',
                    }}
                  />
                </ShowMoreContainer>
              </div>
            </div>
          </div>
          <div className={cx('vinInfo__footer')}>
            <ShowMoreContainer
              btnTextShow={carInfo?.moreOption}
              btnTextHide={carInfo?.moreOptionHide}
              defaultShowStatus={true}
            >
              <span
                dangerouslySetInnerHTML={{
                  __html: carInfo?.disclaimer || '',
                }}
              />
            </ShowMoreContainer>
            <div className={cx('vinInfo__footer-text')}>
              {carInfo?.textBlock}
            </div>
            {carInfo?.dynamicBlock && (
              <div
                className={cx('vinInfo__footer-dynamicBlock')}
                dangerouslySetInnerHTML={{
                  __html: carInfo?.dynamicBlock || '',
                }}
              />
            )}

            <Button
              btnText={carInfo?.submitBtnText}
              onClick={() => {
                setSuccessfullStep();
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Step2;
