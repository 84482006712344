import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  pageResponse: {},
};

export const step4PageDataSlice = createSlice({
  name: 'step4PageResponse',
  initialState,
  reducers: {
    setStep4Response: (state, action) => {
      state.pageResponse = action.payload;
    },
  },
});

export const step4PageResponse = (state) => state.step4PageData.pageResponse;

export const { setStep4Response } = step4PageDataSlice.actions;

export default step4PageDataSlice.reducer;
