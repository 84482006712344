import * as React from "react";

const DeleteIcon = (props) => (
  <svg
    width={17}
    height={17}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 17 17"
    {...props}
  >
    <rect width={17} height={17} rx={8.5} fill="#52565E" />
    <path
      d="m5.844 5.844 5.312 5.312M11.156 5.844l-5.312 5.312"
      stroke="#fff"
      strokeLinejoin="round"
    />
  </svg>
);

export default DeleteIcon;
