import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  pageResponse: null,
};

export const step1PageDataSlice = createSlice({
  name: 'step1PageResponse',
  initialState,
  reducers: {
    setStep1Response: (state, action) => {
      state.pageResponse = action.payload;
    },
  },
});

export const step1PageResponse = (state) => state.step1PageData.pageResponse;

export const { setStep1Response } = step1PageDataSlice.actions;

export default step1PageDataSlice.reducer;
