import React, { useState, useEffect } from 'react';
import classNames from 'classnames/bind';
import styles from './ShowMoreContact.module.scss';

import ArrowCheck from 'assets/svg/arrowCheck';

const cx = classNames.bind(styles);

const ShowMoreContact = ({
  children,
  type,
  btnTextShow,
  btnTextHide,
  onClickedItem,
  indexItem,
}) => {
  const [showMore, setShowMore] = useState(false);

  useEffect(() => {
    if (showMore === false) {
      onClickedItem(null);
    }
  }, [showMore]);

  return (
    <div className={cx('ShowMoreContainer')}>
      <div
        className={type === 'allHide' ? cx('text', 'allHide') : cx('text')}
        style={showMore ? { maxHeight: '2000px' } : {}}
      >
        {children}
      </div>
      <div
        className={showMore ? cx('btnShowMore', 'active') : cx('btnShowMore')}
        onClick={() => setShowMore(!showMore)}
      >
        <span
          className={cx('btnShowMore__text')}
          onClick={() => onClickedItem(indexItem)}
        >
          {showMore
            ? btnTextHide || 'Згорнути деталі'
            : btnTextShow || 'Детальніше'}
        </span>
        <span className={cx('btnShowMore__icon')}>
          <ArrowCheck />
        </span>
      </div>
    </div>
  );
};

export default ShowMoreContact;
