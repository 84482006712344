import React from 'react';
import { useSelector } from 'react-redux';
import ErrorPage from 'steps/ErrorPage';
import Step1 from 'steps/Step1';
import Step2 from 'steps/Step2';
import Step3 from 'steps/Step3';
import Step4 from 'steps/Step4';
import { displayedStep } from 'store/formStepSlice';
import { useGetUrl } from 'utils/getUrlParams';

const StepLayout = () => {
  const actualStep = useSelector(displayedStep);

  useGetUrl();

  const renderStep = () => {
    switch (actualStep) {
      case 1:
        return <Step1 />;
      case 2:
        return <Step2 />;
      case 3:
        return <Step3 />;
      case 4:
        return <Step4 />;
      case 'errorPage':
        return <ErrorPage />;
      default:
        return <Step1 />;
    }
  };
  return <> {actualStep && renderStep()}</>;
};

export default StepLayout;
