import React, { Fragment, Suspense, useEffect, useState } from 'react';
import { Provider } from 'react-redux';

import Spinner from 'components/Spinner/Spinner';
import './App.scss';
import { store } from 'store';
// import 'mapbox-gl/dist/mapbox-gl.css';
import StepLayout from 'components/Layout/Container/StepLayout';
import { getScriptData } from 'api/common';
import { createScript } from 'utils/createScript';

function App() {
  const fetchScriptData = () => {
    getScriptData().then((res) => {
      const headStart = res.data.data.find(
        (sctipt) =>
          sctipt.key == 'toyota.key.robots' ||
          sctipt.key == 'toyota.key.tag.manager.script.head.start',
      )?.value;
      createScript('headStart', headStart);

      const headEnd = res.data.data.find(
        (sctipt) => sctipt.key == 'toyota.key.tag.manager.script.head.end',
      )?.value;
      createScript('headEnd', headEnd);

      const title = res.data.data.find(
        (sctipt) => sctipt.key == 'toyota.key.tag.title',
      )?.value;
      createScript('headEnd', title);

      const description = res.data.data.find(
        (sctipt) => sctipt.key == 'toyota.key.tag.description',
      )?.value;
      createScript('headEnd', description);

      const bodyStart = res.data.data.find(
        (sctipt) => sctipt.key == 'toyota.key.tag.manager.script.body.start',
      )?.value;
      createScript('bodyStart', bodyStart);

      const bodyEnd = res.data.data.find(
        (sctipt) => sctipt.key == 'toyota.key.tag.manager.script.body.end',
      )?.value;
      createScript('bodyEnd', bodyEnd);
    });
  };

  useEffect(() => {
    fetchScriptData();
  }, []);
  return (
    <div className="App">
      <Provider store={store}>
        <Suspense fallback={<Spinner />}>
          <StepLayout />
        </Suspense>
      </Provider>
    </div>
  );
}

export default App;
