import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import styles from './Step3.module.scss';
import Header from 'components/Header';
import MapComponent from 'components/MapComponent';
import { useDispatch, useSelector } from 'react-redux';
import { urlParams } from 'store/urlParamsSlice';
import Button from 'components/Button';
import { setActualStep, setSuccessfulStep_3 } from 'store/formStepSlice';
import { getStepPage_3, searchDealerRequest } from 'api/step_3';
import { setErrorData } from 'store/errorsSlice';
import {
  dealerResponse,
  setStaticDealer,
  setStep3Response,
  step3PageResponse,
  staticDealer,
  setSelectedDealer,
} from 'store/step3PageDataSlice';
import Spinner from '../../components/Spinner/Spinner';

const cx = classNames.bind(styles);

const Step3 = () => {
  const dispatch = useDispatch();
  const pageData = useSelector(step3PageResponse);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingDealerInfo, setIsLoadingDealerInfo] = useState(false);

  const urlParamsData = useSelector(urlParams);
  const dealerList = useSelector(dealerResponse);
  const dealerInfo = useSelector(staticDealer);
  const { dealer } = urlParamsData;

  const getStepPageData = () => {
    setIsLoading(true);
    getStepPage_3(urlParamsData)
      .then((res) => {
        dispatch(setStep3Response(res.data.data));
        setIsLoading(false);
      })
      .catch((error) => {
        dispatch(setErrorData(error.response));
        dispatch(setActualStep('errorPage'));
        setIsLoading(false);
      });
  };

  useEffect(() => {
    !dealer && getStepPageData();
  }, []);

  useEffect(() => {
    if (dealer && !dealerInfo) {
      setIsLoading(true);
      searchDealerRequest({
        lang: urlParamsData.lang,
        mark: urlParamsData.mark,
        distance: 2000,
        latitude: 59.804904,
        longitude: 30.320802,
      })
        .then((res) => {
          if (res) {
            const filteredItems = res.data.data.filter(
              (item) =>
                item.dealerCode.toLowerCase() ===
                urlParamsData.dealer.toLowerCase(),
            );
            dispatch(setStaticDealer(filteredItems[0]));
          }
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
        });
    }
  }, [!dealerInfo]);

  return (
    <div className={cx('step3')}>
      <Header title={pageData.title} stepNumber="3" />
      <>
        {!dealer ? (
          <div className={cx('map')}>
            {!isLoading ? (
              <MapComponent
                dealerList={dealerList}
                mapBoxKey={pageData.mapBoxKey}
              />
            ) : (
              <Spinner />
            )}
          </div>
        ) : (
          <div className={cx('dealer')}>
            {!isLoading ? (
              <>
                <div className={cx('dealer__title')}>{dealerInfo?.name}</div>
                <div className={cx('dealer__list')}>
                  {dealerInfo &&
                    dealerInfo?.fields.map((el, index) => (
                      <div className={cx('dealer__row')} key={index}>
                        <div className={cx('dealer__row-td', 'td-fildName')}>
                          {el.label}
                        </div>
                        <div
                          className={cx('dealer__row-td', 'td-value')}
                          dangerouslySetInnerHTML={{
                            __html: el.value || '',
                          }}
                        />
                      </div>
                    ))}
                </div>
                <div className={cx('dealer__btn-wrap')}>
                  <Button
                    btnText={dealerInfo?.btnChooseDealerText}
                    onClick={() => {
                      dispatch(setSelectedDealer(urlParamsData.dealer));
                      dispatch(setSuccessfulStep_3(true));
                      dispatch(setActualStep(4));
                    }}
                  />
                </div>
              </>
            ) : (
              <Spinner />
            )}
          </div>
        )}
      </>
    </div>
  );
};

export default Step3;
