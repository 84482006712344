import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  error: {},
};

export const errorsSlice = createSlice({
  name: "errorResponce",
  initialState,
  reducers: {
    setErrorData: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const errorData = (state) => state.errorResponce.error;

export const { setErrorData } = errorsSlice.actions;

export default errorsSlice.reducer;
