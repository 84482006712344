import React from "react";
import classNames from "classnames/bind";
import styles from "./footer.module.scss";

const cx = classNames.bind(styles);

const Footer = ({ children = "" }) => {
  return <div className={cx("footer")}>{children}</div>;
};

export default Footer;
