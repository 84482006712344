import * as React from "react";

const MarkerIcon = (props) => (
  <svg
    width={32}
    height={55}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 55"
    {...props}
  >
    <path
      opacity={0.9}
      d="M31.5 17.333c0 2.236-.967 5.681-2.468 9.652-1.492 3.95-3.485 8.35-5.482 12.468a306.748 306.748 0 0 1-5.49 10.74A302.1 302.1 0 0 1 16 53.975l-.25-.452a302.1 302.1 0 0 1-1.81-3.33 306.764 306.764 0 0 1-5.49-10.74c-1.997-4.117-3.99-8.519-5.482-12.468C1.468 23.015.5 19.569.5 17.333.5 8.208 6.936.5 16 .5s15.5 7.708 15.5 16.833Z"
      fill="#fff"
      stroke="#E4E4E4"
    />
  </svg>
);

export default MarkerIcon;
