import React, { useState, useEffect } from 'react';
import { useController } from 'react-hook-form';
import classNames from 'classnames/bind';

import styles from './style.module.scss';
import Tooltip from 'components/Tooltip/Tooltip';
const cx = classNames.bind(styles);

const FormInput = (props) => {
  const { field } = useController(props);
  const [focus, setFocus] = useState(false);

  const required = props?.rules?.required;

  useEffect(() => {
    if (field.value && field.value !== undefined) {
      setFocus(true);
    }
  }, [field.value]);

  return (
    <div
      className={
        props.disabled ? cx('inputContainer disabled') : cx('inputContainer')
      }
    >
      <div
        className={props.errors ? cx('inputWrap', 'error') : cx('inputWrap')}
      >
        <div className={focus ? cx('label', 'focus') : cx('label')}>
          {props.label}{' '}
          {required && !props.disabled ? (
            <span className={cx('required')}>*</span>
          ) : null}
        </div>
        <input
          type="text"
          {...field}
          {...props}
          className={focus ? cx('input', 'focus') : cx('input')}
          disabled={props.disabled}
          value={field.value}
          onFocus={() => {
            setFocus(true);
          }}
          onBlur={() => !field.value && setFocus(false)}
          onChange={(e) => {
            if (props.maxLength && field.value !== undefined) {
              if (field.value.length <= props.maxLength) {
                if (props.handleChange) {
                  props.handleChange(e.target.value, field.name);
                }
                field.onChange(e.target.value);
              }
            } else {
              if (props.handleChange) {
                props.handleChange(e.target.value, field.name);
              }
              field.onChange(e.target.value);
            }
          }}
        />
        {props.tooltip && (
          <div className={cx('tooltipWrap')}>
            <Tooltip
              tooltipText={props?.tooltip}
              dataEvent="click"
              dataFor={'tooltip-' + field.name}
            />
          </div>
        )}
      </div>
      {props.errors && (
        <p className={cx('errorName')}>{props.errors?.message}</p>
      )}
    </div>
  );
};

export default FormInput;
