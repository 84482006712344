import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  headerResponce: null,
};

export const headerSlice = createSlice({
  name: 'headerResponce',
  initialState,
  reducers: {
    setHeaderResponce: (state, action) => {
      state.headerResponce = action.payload;
    },
  },
});

export const headerResponce = (state) => state.headerData.headerResponce;

export const { setHeaderResponce } = headerSlice.actions;

export default headerSlice.reducer;
