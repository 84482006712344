export const createScript = (forTag, scripts) => {
  const range = document.createRange();
  switch (forTag) {
    case 'headEnd':
      {
        const head = document.getElementsByTagName('head')[0];
        try {
          range.setStart(head);
        } catch (err) {
          range.setStart(head, 0); // - didn't work for moderately old iOS
        }
        head.appendChild(range.createContextualFragment(scripts));
      }
      break;
    case 'headStart':
      {
        const head = document.getElementsByTagName('head')[0];
        try {
          range.setStart(head);
        } catch (err) {
          range.setStart(head, 0); // - didn't work for moderately old iOS
        }
        head.insertBefore(
          range.createContextualFragment(scripts),
          head.firstChild,
        );
      }
      break;
    case 'bodyStart':
      {
        range.selectNode(document.body);
        const body = document.body;
        body.insertBefore(
          range.createContextualFragment(scripts),
          body.firstChild,
        );
      }
      break;
    case 'bodyEnd': {
      range.selectNode(document.body);
      const body = document.body;
      body.appendChild(range.createContextualFragment(scripts));
    }
  }
};
