import * as React from "react";

const CalendarIcon = (props) => (
  <svg
    width={20}
    height={20}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.5 10h-15V8.5h15V10ZM7.5 0v5.833H6V0h1.5ZM14.166 0v5.833h-1.5V0h1.5Z"
      fill="#282830"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.833 3.167H3.167V18.5h13.666V3.166Zm-15.166-1.5V20h16.666V1.666H1.667Z"
      fill="#282830"
    />
  </svg>
);

export default CalendarIcon;
