import API from '../api';

export const getStepPage_4 = (params) => {
  return API.post(`/api/v1/step/four/index`, params);
};

export const sendServiceRequest = (params, data) => {
  let paramsString = new URLSearchParams(params);

  return API.post(`/api/v1/step/four?${paramsString}`, data);
};
