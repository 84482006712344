import React, { useState, useEffect } from 'react';
import { useController } from 'react-hook-form';
import classNames from 'classnames/bind';

import styles from './style.module.scss';

const cx = classNames.bind(styles);

const CheckBoxInput = (props) => {
  const { field } = useController(props);
  const [focus, setFocus] = useState(false);

  const required = props?.rules?.required;

  useEffect(() => {
    if (field.value) {
      setFocus(true);
    }
  }, [field.value]);

  return (
    <div className={props.disabled ? cx('disabled') : ''}>
      <div
        className={props.errors ? cx('container', 'error') : cx('container')}
      >
        <label className={cx('labelWrap')}>
          <input
            type="checkbox"
            {...props}
            {...field}
            className={focus ? cx('checkbox', 'focus') : cx('checkBox')}
            disabled={props.disabled}
            value={field.value}
            name={field.name}
            checked={field.value}
            onFocus={() => {
              setFocus(true);
            }}
            onBlur={() => !field.value && setFocus(false)}
          />
          <span className={cx('checkmark')}></span>
          <span className={cx('label-wrap')}>
            <span
              className={cx('label')}
              dangerouslySetInnerHTML={{ __html: props.label }}
            />{' '}
            <a
              className={cx('label__link')}
              onClick={props.labellinkaction}
              dangerouslySetInnerHTML={{ __html: props.labellinktext }}
            ></a>
            {required && !props.disabled ? (
              <span className={cx('required')}>*</span>
            ) : null}
          </span>
        </label>
      </div>
    </div>
  );
};

export default CheckBoxInput;
