import React, { useState, useEffect } from 'react';
import classNames from 'classnames/bind';

import styles from './style.module.scss';

const cx = classNames.bind(styles);

const CheckBoxInputGroup = (props) => {
  const { items } = props;

  const [checked, setChecked] = useState([]);
  const handleCheck = (event) => {
    var updatedList = [...checked];
    if (event.target.checked) {
      updatedList = [...checked, +event.target.value];
    } else {
      updatedList.splice(checked.indexOf(event.target.value), 1);
    }
    setChecked(updatedList);
  };

  useEffect(() => {
    props.getAdditionalArr(checked);
  }, [checked]);

  return (
    <fieldset className={cx('checkBoxList')}>
      {items &&
        items?.map((el, index) => (
          <div className={cx('container')} key={index}>
            <label className={cx('labelWrap')}>
              <input
                key={index}
                className={focus ? cx('checkbox', 'focus') : cx('checkBox')}
                type="checkbox"
                name={props.name}
                value={+el.value}
                onChange={(event) => {
                  handleCheck(event);
                }}
              />
              <span className={cx('checkmark')}></span>
              <span className={cx('label-wrap')}>
                <span
                  className={cx('label')}
                  dangerouslySetInnerHTML={{ __html: el.label }}
                />{' '}
              </span>
            </label>
          </div>
        ))}
    </fieldset>
  );
};

export default CheckBoxInputGroup;
