import React, { useState, useEffect, useRef } from 'react';

export const getWindowWidth = () => {
  const windowSize = useRef([window.innerWidth, window.innerHeight]);
  const [width, setWidth] = useState(windowSize.current[0]);

  useEffect(() => {
    const handleWindowResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [windowSize]);
  return width;
};
