import API from '../api';

export const getStepPage_1 = (params) => {
  let paramsString = new URLSearchParams(params);
  return API.get(`/api/v1/step/one?${paramsString}`);
};

export const sendCarParams = (params, data) => {
  let paramsString = new URLSearchParams(params);
  return API.post(`/api/v1/step/one?${paramsString}`, data);
};
