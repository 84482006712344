import * as React from 'react';

const TooltipIcon = (props) => (
  <svg
    width={17}
    height={18}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    viewBox="0 0 17 18"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.25 15.75A6.74 6.74 0 0 1 1.5 9a6.74 6.74 0 0 1 6.75-6.75A6.74 6.74 0 0 1 15 9a6.74 6.74 0 0 1-6.75 6.75ZM0 9a8.24 8.24 0 0 0 8.25 8.25A8.24 8.24 0 0 0 16.5 9 8.24 8.24 0 0 0 8.25.75 8.24 8.24 0 0 0 0 9Z"
      fill={props.color || '#282830'}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m8.658 10.07.241-5H7.5l.192 5h.966Z"
      fill={props.color || '#282830'}
    />
    <path
      d="M9 12.18a.75.75 0 1 0-1.5 0 .75.75 0 0 0 1.5 0Z"
      fill={props.color || '#282830'}
    />
  </svg>
);

export default TooltipIcon;
