import React, { useEffect, useState, memo } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import classNames from 'classnames/bind';
import styles from './TabsComponent.module.scss';

const cx = classNames.bind(styles);

const TabsComponent = ({ tabsList, tablesList }) => {
  const [value, setValue] = useState(0);
  const [activeSection, setActiveSection] = React.useState('');
  const [tablesListArr, setTablesListArr] = useState([]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
      <div
        className={cx('tabs__panel-content')}
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3, paddingLeft: 0, paddingRight: 0, paddingBottom: 0 }}>
            {children}
          </Box>
        )}
      </div>
    );
  };

  const getTablesItem = () => {
    let newArr = [];

    tabsList.map((el) => {
      newArr.push(tablesList[el.value]);
    });
    setTablesListArr(newArr);
  };

  const renderDataSection = (activeSectionData) => {
    if (activeSection === 'Нормативи і вартість') {
      return (
        <div className={cx('sectionData', 'required')}>
          <div className={cx('sectionData__content')}>
            <div className={cx('sectionData__content-body')}>
              {activeSectionData?.rows?.map((item, index) => (
                <div className={cx('sectionData__item')} key={index}>
                  <div className={cx('sectionData__col', 'label')}>
                    {item.label}
                  </div>
                  <div className={cx('sectionData__col', 'value')}>
                    {item.value}
                  </div>
                </div>
              ))}
            </div>
            <div className={cx('sectionData__content-footer')}>
              {activeSectionData.warning}
            </div>
          </div>
        </div>
      );
    }
    if (activeSection === 'Контрольно-оглядові роботи') {
      return (
        <div className={cx('sectionData', 'diagnostic')}>
          <div className={cx('sectionData__content')}>
            <div className={cx('sectionData__content-body')}>
              {activeSectionData?.rows?.map((item, index) => (
                <div className={cx('sectionData__item')} key={index}>
                  <div className={cx('sectionData__col', 'label')}>
                    {item['col-1']?.text}
                  </div>
                  <div className={cx('sectionData__col', 'value')}>
                    {item['col-2']?.text}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      );
    }
    if (activeSection === 'Регламентні перевірки і заміни') {
      return (
        <div className={cx('sectionData', 'regulatory')}>
          <div className={cx('sectionData__content')}>
            <div className={cx('sectionData__content-body')}>
              {activeSectionData?.rows?.map((item, index) => (
                <div className={cx('sectionData__item')} key={index}>
                  <div className={cx('sectionData__col', 'label')}>
                    {item['col-1']?.text}
                  </div>
                  <div className={cx('sectionData__col', 'value')}>
                    {item['col-2']?.text}
                  </div>
                  <div className={cx('sectionData__col', 'price')}>
                    {item['col-3']?.text}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      );
    }
    if (activeSection === 'Витратні матеріали') {
      return (
        <div className={cx('sectionData', 'materials')}>
          <div className={cx('sectionData__content')}>
            <div className={cx('sectionData__content-body')}>
              {activeSectionData?.rows?.map((item, index) => (
                <div className={cx('sectionData__item')} key={index}>
                  <div className={cx('sectionData__col', 'label')}>
                    {item['col-1']?.text}
                  </div>
                  <div className={cx('sectionData__col', 'value')}>
                    {item['col-2']?.text}
                  </div>
                  <div className={cx('sectionData__col', 'price')}>
                    {item['col-3']?.text}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      );
    }
  };

  useEffect(() => getTablesItem(), [tablesList]);
  useEffect(() => setActiveSection(tabsList[value]?.text), [value]);

  return (
    <Box
      sx={{
        maxWidth: { xs: 320, sm: 480 },
        bgcolor: 'background.paper',
      }}
    >
      <Tabs
        className={cx('tabs')}
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons
        allowScrollButtonsMobile
        aria-label="scrollable force tabs example"
      >
        {tabsList.map((el, index) => (
          <Tab label={el.text} key={index} className={cx('tabs__tab')} />
        ))}
      </Tabs>

      {tablesListArr.map((el, index) => (
        <TabPanel
          value={value}
          index={index}
          key={index}
          className={cx('tabs__panel')}
        >
          {renderDataSection(el)}
        </TabPanel>
      ))}
    </Box>
  );
};

export default memo(TabsComponent);
