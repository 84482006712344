import React from 'react';

import classNames from 'classnames/bind';
import styles from './ChatBotTabs.module.scss';
import { Box, Tab, Tabs, Typography } from '@mui/material';
import Messenger from 'assets/svg/Messenger';
import Viber from 'assets/svg/Viber';
import Telegram from 'assets/svg/Telegram';
import { getWindowWidth } from 'utils/getWindowWidth';

const cx = classNames.bind(styles);

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};
const a11yProps = (index) => {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
};

const ChatBotTabs = ({ qrCodeData, title, signupText }) => {
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const width = getWindowWidth() < 992;

  const getQrCodeImage = (name) => {
    let qrCode = qrCodeData.filter((el) => el.name === name)[0].qrCode;
    return qrCode;
  };

  const getLink = (name) => {
    let link = qrCodeData.filter((el) => el.name === name)[0].phoneLink;
    return width && window.open(link, '_blank');
  };

  return (
    <div className={cx('chatBot')}>
      <div className={cx('tabs')}>
        <div className={cx('tabs__title')}>{title}</div>
        <Box className={cx('socialTabs')}>
          <Tabs
            value={value}
            onChange={handleChange}
            classes={{
              indicator: cx('active'),
              scroller: cx('scroller'),
              flexContainer: cx('flexContainer'),
            }}
          >
            {qrCodeData.map((el, index) => (
              <Tab
                className={cx('tabs__label')}
                label={<div className={cx('tabs__label-text')}>{el.name}</div>}
                icon={<img src={el.photo} />}
                onClick={() => getLink(el.name)}
                {...a11yProps(index)}
              />
            ))}
          </Tabs>
          <div className={cx('tabPanelList')}>
            {qrCodeData.map((qrCode, index) => (
              <TabPanel value={value} index={index} className={cx('tabPanel')}>
                <div className={cx('tabPanel__qr-code')}>
                  <img src={getQrCodeImage(qrCode.name)} alt={qrCode.name} />
                </div>
                <div className={cx('tabPanel__qr-code-text')}>
                  {signupText || 'no text'}
                </div>
              </TabPanel>
            ))}
          </div>
        </Box>
      </div>
    </div>
  );
};

export default ChatBotTabs;
