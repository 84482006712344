import React, { useState } from "react";
import classNames from "classnames/bind";
import styles from "./RatioComponent.module.scss";
const cx = classNames.bind(styles);

const RatioComponent = ({ defaultChecked = null, opt = [], onChangeFc }) => {
  const [checkedItem, setCheckedItem] = useState(defaultChecked);

  const handleChecked = (value) => {
    setCheckedItem(value);
  };

  return (
    <div className={cx("ratioComponent")}>
      {opt.map((el) => (
        <div
          className={cx(
            "ratioComponent__item",
            checkedItem === el.value ? "active" : ""
          )}
          key={el.label}
          onClick={() => {
              handleChecked(el.value)
              onChangeFc(el.value)
            }
          }
        >
          <div className={cx("ratioComponent__item-label")}>{el.label}</div>
        </div>
      ))}
    </div>
  );
};

export default RatioComponent;
