import * as React from 'react';

const ActiveMarkerIcon = (props) => (
  <svg
    width={32}
    height={55}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 55"
    {...props}
  >
    <path
      d="M32 17.333C32 26.68 16 55 16 55S0 26.68 0 17.333C0 7.987 6.608 0 16 0s16 7.987 16 17.333Z"
      fill="#282830"
      style={{
        mixBlendMode: 'multiply',
      }}
      opacity={0.9}
    />
  </svg>
);

export default ActiveMarkerIcon;
