import React, { useState, useRef, useEffect } from 'react';
import classNames from 'classnames/bind';
import styles from './ShowMoreContainer.module.scss';

import ArrowCheck from 'assets/svg/arrowCheck';

const cx = classNames.bind(styles);

const ShowMoreContainer = ({
  children,
  type,
  btnTextShow,
  btnTextHide,
  forceShow,
}) => {
  const [showMore, setShowMore] = useState(true);
  const [height, setHeight] = useState(0);
  const ref = useRef(null);

  useEffect(() => {
    setHeight(ref.current.clientHeight);
  }, [children]);

  useEffect(() => {
    console.log('showMore: ', showMore);
  }, [showMore]);

  return (
    <div className={cx('ShowMoreContainer')}>
      <div
        ref={ref}
        className={type === 'allHide' ? cx('text', 'allHide') : cx('text')}
        style={showMore ? { maxHeight: '2000px' } : {}}
      >
        <span>{children}</span>
      </div>
      {height > 60 || forceShow || type === 'allHide' ? (
        <div
          className={showMore ? cx('btnShowMore', 'active') : cx('btnShowMore')}
          onClick={() => setShowMore(!showMore)}
        >
          <span className={cx('btnShowMore__icon')}>
            <ArrowCheck />
          </span>
          <span className={cx('btnShowMore__text')}>
            {showMore
              ? btnTextHide || 'Згорнути деталі'
              : btnTextShow || 'Детальніше'}
          </span>
        </div>
      ) : null}
    </div>
  );
};

export default ShowMoreContainer;
