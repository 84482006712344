import * as React from "react";

const LocationIcon = (props) => (
  <svg
    width={20}
    height={20}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="m5 8.886 4.508 1.13 1.279 4.875L14.42 5 5 8.886Z"
      stroke="#282830"
      strokeWidth={1.5}
      strokeLinecap="round"
    />
  </svg>
);

export default LocationIcon;
