import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  urlParams: {
    dealer: null,
    lang: null,
    source: null,
    mark: null,
  },
};

export const urlParamslice = createSlice({
  name: "urlParams",
  initialState,
  reducers: {
    setUrlParams: (state, action) => {
      state.urlParams = action.payload;
    },
  },
});

export const urlParams = (state) => state.urlParams.urlParams;

export const { setUrlParams } = urlParamslice.actions;

export default urlParamslice.reducer;
