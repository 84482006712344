import coordinatesCountry from 'const/boundaries.json';
import MAP_STYLE from 'const/mapStyle.json';

const sfNeighborhoods = {
  type: 'geojson',
  data: coordinatesCountry,
};

const fillLayer = {
  id: 'sf-neighborhoods-fill',
  source: 'sf-neighborhoods',
  type: 'fill',
  paint: {
    'fill-outline-color': '#ffffff',
    'fill-color': '#ffffff',
    'fill-opacity': 0,
  },
};

const lineLayer = {
  id: 'sf-neighborhoods-outline',
  source: 'sf-neighborhoods',
  type: 'line',
  paint: {
    'line-width': 1,
    'line-color': '#ff0022',
  },
};

// Make a copy of the map style
export default {
  ...MAP_STYLE,
  sources: {
    ...MAP_STYLE.sources,
    ['sf-neighborhoods']: sfNeighborhoods,
  },
  layers: [...MAP_STYLE.layers, fillLayer, lineLayer],
};
