import React from "react";
import classNames from "classnames/bind";
import styles from "./button.module.scss";

const cx = classNames.bind(styles);

const Spinner = () => {
  return (
    <div className={cx("lds-ellipsis")}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

const Button = ({ btnText, btnType, onClick, disabled }) => {
  return (
    <button
      className={cx("button", btnType, disabled ? "disabled" : "")}
      onClick={onClick}
      disabled={disabled}
    >
      {disabled ? <Spinner /> : btnText}
    </button>
  );
};

export default Button;
