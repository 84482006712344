import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  displayedStep: 1,
  successfulStep_1: false,
  successfulStep_2: false,
  successfulStep_3: false,
  successfulStep_4: false,
};

export const formStepSlice = createSlice({
  name: 'formSteps',
  initialState,
  reducers: {
    setActualStep: (state, action) => {
      state.displayedStep = action.payload;
    },
    setSuccessfulStep_1: (state, acton) => {
      state.successfulStep_1 = acton.payload;
    },
    setSuccessfulStep_2: (state, acton) => {
      state.successfulStep_2 = acton.payload;
    },
    setSuccessfulStep_3: (state, acton) => {
      state.successfulStep_3 = acton.payload;
    },
    setSuccessfulStep_4: (state, acton) => {
      state.successfulStep_4 = acton.payload;
    },
  },
});

export const displayedStep = (state) => state.formSteps.displayedStep;
export const successfulStep_1 = (state) => state.formSteps.successfulStep_1;
export const successfulStep_2 = (state) => state.formSteps.successfulStep_2;
export const successfulStep_3 = (state) => state.formSteps.successfulStep_3;
export const successfulStep_4 = (state) => state.formSteps.successfulStep_4;

export const {
  setActualStep,
  setSuccessfulStep_1,
  setSuccessfulStep_2,
  setSuccessfulStep_3,
  setSuccessfulStep_4,
} = formStepSlice.actions;

export default formStepSlice.reducer;
