import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  firstStep: {
    vin: "",
    mileage: "",
  },
};

export const formDataSlice = createSlice({
  name: "formData",
  initialState,
  reducers: {
    setFirstStepData: (state, action) => {
      state.firstStep = action.payload;
    },
  },
});

export const firstStepData = (state) => state.formData.firstStep;

export const { setFirstStepData } = formDataSlice.actions;

export default formDataSlice.reducer;
