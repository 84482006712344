import * as React from "react";

const CheckWhiteIcon = (props) => (
  <svg
    width={25}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 25 24"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.207 6.293a1 1 0 0 1 0 1.414l-11 11a1 1 0 0 1-1.414 0l-5-5a1 1 0 1 1 1.414-1.414L9.5 16.586 19.793 6.293a1 1 0 0 1 1.414 0Z"
      fill="#fff"
    />
  </svg>
);

export default CheckWhiteIcon;
