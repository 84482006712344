import { combineReducers } from '@reduxjs/toolkit';

import formStepsReducer from './formStepSlice';
import urlParamsReducer from './urlParamsSlice';
import formDataReducer from './formDataSlice';
import errorResponceReducer from './errorsSlice';
import step1PageDataSliceReducer from './step1PageDataSlice';
import step2PageDataSliceReducer from './step2PageDataSlice';
import step3PageDataSliceReducer from './step3PageDataSlice';
import step4PageDataSliceReducer from './step4PageDataSlice';
import headerSliceReducer from './headerSlice';

const rootReducer = combineReducers({
  formSteps: formStepsReducer,
  urlParams: urlParamsReducer,
  formData: formDataReducer,
  errorResponce: errorResponceReducer,
  step1PageData: step1PageDataSliceReducer,
  step2PageData: step2PageDataSliceReducer,
  step3PageData: step3PageDataSliceReducer,
  step4PageData: step4PageDataSliceReducer,
  headerData: headerSliceReducer,
});

export default rootReducer;
