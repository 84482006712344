import * as React from "react";

const SearchIcon = (props) => (
  <svg
    width={17}
    height={18}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 17 18"
    {...props}
  >
    <path
      d="m14.96 15.39-4.25-4.5"
      stroke="#282830"
      strokeWidth={1.5}
      strokeLinejoin="round"
    />
    <path
      clipRule="evenodd"
      d="M7.225 12C9.807 12 11.9 9.89 11.9 7.286c0-2.604-2.093-4.715-4.675-4.715S2.55 4.681 2.55 7.286C2.55 9.889 4.643 12 7.225 12Z"
      stroke="#282830"
      strokeWidth={1.5}
    />
  </svg>
);

export default SearchIcon;
