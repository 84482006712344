import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  pageResponse: {},
};

export const step2PageDataSlice = createSlice({
  name: "step2PageResponse",
  initialState,
  reducers: {
    setStep2Response: (state, action) => {
      state.pageResponse = action.payload;
    },
  },
});

export const step2PageResponse = (state) => state.step2PageData.pageResponse;

export const { setStep2Response } = step2PageDataSlice.actions;

export default step2PageDataSlice.reducer;
